import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { environment } from '../../environments/environment';

const OFF_LINE_MESSAGE = [
  'ネットワークに接続できません。',
  '接続を確認の上、ページを再読み込みしてください。',
].join('\n');

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      if (navigator.onLine) {
        this.log(error);
      } else {
        alert(OFF_LINE_MESSAGE);
      }
    } else {
      this.log(error);
    }
  }

  private log(error: Error | HttpErrorResponse) {
    if (!environment.production) {
      console.error(error);
    }
  }
}
