import {
  Directive,
  Input,
  OnChanges,
  ViewContainerRef,
  TemplateRef,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { UserService } from '../../service/user.service';

@Directive({
  selector: '[lsAllowedGroups]',
  exportAs: 'lsAllowedGroups',
})
export class AllowedGroupsDirective implements OnChanges, OnDestroy {
  @Input('lsAllowedGroups')
  groups: string[];

  onDestroy$ = new EventEmitter();

  constructor(
    private container: ViewContainerRef,
    private template: TemplateRef<any>,
    private user: UserService,
  ) {
    this.user.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => this.initView());
  }

  ngOnChanges() {
    this.initView();
  }

  ngOnDestroy() {
    this.onDestroy$.emit();
  }

  initView() {
    this.container.clear();
    if (
      !!this.groups &&
      !!this.user.hasEnableProjectUserData &&
      this.groups!.filter(
        (group) =>
          this.user.groups!.filter((userGrup) => userGrup.group === group)
            .length > 0,
      ).length > 0
    ) {
      this.container.createEmbeddedView(this.template);
    }
  }
}
