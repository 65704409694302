import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DuplicateProjectResult } from 'app/v2/general/domain/types/duplicate-project-result';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { LogFilterParams } from '../models/request/log-filter-params';
import { ProjectSearchParams } from '../models/request/project-search-params';
import { ExteriorCategory } from '../models/response/exterior-category';
import { ExteriorSet } from '../models/response/exterior-set';
import { FloorGroup } from '../models/response/floor-group';
import { InteriorCategory } from '../models/response/interior-category';
import { InteriorSet } from '../models/response/interior-set';
import { InteriorSetParts } from '../models/response/interior-set-parts';
import { Invite } from '../models/response/invite';
import { ListLog } from '../models/response/list-log';
import { Pagination } from '../models/response/pagination';
import { Picklist } from '../models/response/picklist';
import { PicklistGroup } from '../models/response/picklist_group';
import { Project } from '../models/response/project';
import { ProjectSearchInfo } from '../models/response/project-search-info';
import { Room } from '../models/response/room';
import { RoomBlock } from '../models/response/room-block';
import { RoomGroup } from '../models/response/room-group';
import { ColorImage } from '../models/response/sub/color-image';
import { ListLogType } from '../models/response/sub/list-log-type';
import { MemberJobType } from '../models/response/sub/member-job-type';
import { PicklistProductStatus } from '../models/response/sub/picklist-product-status';
import { ProjectType } from '../models/response/sub/project-type';
import { User } from '../models/response/user';

import { RequestParamsConverterService } from './request-params-converter.service';

export interface FavProductInfoUpdateParams {
  product_unique_key: string;
  status: PicklistProductStatus;
  tags: {
    [tag: string]: boolean;
  };
  with_variation: boolean;
}

export interface ImportBimResult {
  canceledGroupingRooms: {
    roomGroup: RoomGroup;
    rooms: Room[];
  }[];
  log: any;
  error?: { message: string; trace?: string }; //バックエンド例外発生時に付与されるプロパティ
}

export interface ExportBimResult {
  data: string;
  fileName: string;
  error?: { message: string; trace?: string }; //バックエンド例外発生時に付与されるプロパティ
}

export interface InteriorPicklistParams {
  picklist: Picklist;
  parts?: InteriorSetParts;
  remove?: boolean;
  isManualEdit?: boolean;
  isSetUpdatedProductInfoAt?: boolean;
  copy_from?: Picklist;
}

export interface ExteriorPicklistParams {
  picklist: Picklist;
  set?: ExteriorSet;
  remove?: boolean;
  isManualEdit?: boolean;
  isSetUpdatedProductInfoAt?: boolean;
  copy_from?: Picklist;
}

export interface OutdoorPicklistParams {
  picklist: Picklist;
  remove?: boolean;
  isManualEdit?: boolean;
  isSetUpdatedProductInfoAt?: boolean;
  picklistGroup?: PicklistGroup;
  copy_from?: Picklist;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  // 自分の操作で自分の画面のfinishScheduleの同期処理を走らせたくない時にnextさせる
  onBeginUpdate$ = new Subject<void>();

  constructor(
    protected http: HttpClient,
    protected converter: RequestParamsConverterService,
  ) {}

  show(projectId: number): Observable<Project> {
    return this.http.get<Project>(`/api/project/${projectId}`);
  }

  store(project: Project): Observable<Project> {
    return this.http.post<Project>(`/api/project`, {
      project,
    });
  }

  update(project: Project): Observable<Project> {
    this.onBeginUpdate$.next();
    return this.http.put<Project>(`/api/project/${project.id}`, project);
  }

  finishSchedule(projectId: number): Observable<Project> {
    return this.http.get<Project>(`/api/project/${projectId}/finish-schedule`);
  }

  members(projectId: number): Observable<User[]> {
    return this.http.get<User[]>(`/api/project/${projectId}/members`);
  }

  updateMember(projectId: number, member: User): Observable<User> {
    return this.http.put<User>(`/api/project/${projectId}/members`, { member });
  }

  invites(projectId: number): Observable<Invite[]> {
    return this.http.get<Invite[]>(`/api/project/${projectId}/invites`);
  }

  updateInvite(
    projectId: number,
    inviteId: number,
    info: Invite,
  ): Observable<Invite> {
    return this.http.put<Invite>(
      `/api/project/${projectId}/invites/update/${inviteId}`,
      { info },
    );
  }

  sendInvite(
    projectId: number,
    mail: string,
    memberJobType: MemberJobType,
  ): Observable<Invite> {
    return this.http.post<Invite>(`/api/project/${projectId}/invites/send`, {
      mail,
      memberJobType,
    });
  }

  cancelInvite(
    projectId: number,
    inviteId?: number,
    isSkipLog?: boolean,
  ): Observable<any> {
    isSkipLog = isSkipLog ? isSkipLog : false;
    return this.http.post<any>(
      `/api/project/${projectId}/invites/cancel/${inviteId}`,
      { isSkipLog },
    );
  }

  exclusionMember(projectId: number, userId: number): Observable<any> {
    return this.http.get<any>(
      `/api/project/${projectId}/members/exclusion/${userId}`,
    );
  }

  addRoom(
    projectId: number,
    target: { floorGroup: FloorGroup; roomGroups: RoomGroup[] },
  ): Observable<{
    floorGroup: FloorGroup;
    roomGroups: {
      roomGroup: RoomGroup;
      set: InteriorSet;
      newPicklists: Picklist[];
    }[];
  }> {
    this.onBeginUpdate$.next();
    return this.http.post<{
      floorGroup: FloorGroup;
      roomGroups: {
        roomGroup: RoomGroup;
        set: InteriorSet;
        newPicklists: Picklist[];
      }[];
    }>(`/api/project/${projectId}/finish-schedule/add-room`, target);
  }

  addExteriorSet(
    projectId: number,
    target: { category: ExteriorCategory; sets: ExteriorSet[] },
  ): Observable<{
    category: ExteriorCategory;
    sets: { set: ExteriorSet; newPicklists: Picklist[] }[];
  }> {
    this.onBeginUpdate$.next();
    return this.http.post<{
      category: ExteriorCategory;
      sets: { set: ExteriorSet; newPicklists: Picklist[] }[];
    }>(`/api/project/${projectId}/finish-schedule/add-exterior-set`, target);
  }

  saveParts(
    projectId: number,
    target: { interiorSet: InteriorSet; parts: InteriorSetParts },
  ): Observable<{ interiorSet: InteriorSet; parts: InteriorSetParts }> {
    this.onBeginUpdate$.next();
    return this.http.post<{
      interiorSet: InteriorSet;
      parts: InteriorSetParts;
    }>(`/api/project/${projectId}/finish-schedule/save-parts`, target);
  }

  savePicklist(
    projectId: number,
    target:
      | InteriorPicklistParams
      | ExteriorPicklistParams
      | OutdoorPicklistParams,
  ): Observable<
    InteriorPicklistParams | ExteriorPicklistParams | OutdoorPicklistParams
  > {
    this.onBeginUpdate$.next();
    return this.http.post<InteriorPicklistParams | ExteriorPicklistParams>(
      `/api/project/${projectId}/finish-schedule/picklist/${target.picklist.id}`,
      target,
    );
  }

  saveRoomBlock(
    projectId: number,
    roomGroupId: number,
    roomBlock: RoomBlock[],
  ): Observable<RoomBlock[]> {
    this.onBeginUpdate$.next();
    return this.http.post<RoomBlock[]>(
      `/api/project/${projectId}/finish-schedule/room-block/${roomGroupId}`,
      { roomBlock: roomBlock },
    );
  }

  saveOrder(
    projectId: number,
    categoryTarget: { key: string; picklistIds: number[] },
  ) {
    this.onBeginUpdate$.next();
    return this.http.post(
      `/api/project/${projectId}/interior/picklist/save-order`,
      categoryTarget,
    );
  }

  addListLink(
    projectId: number,
    currentPicklist: Picklist,
    picklist: Picklist,
  ): Observable<{ from: Picklist; to: Picklist }> {
    this.onBeginUpdate$.next();
    return this.http.get<{ from: Picklist; to: Picklist }>(
      `/api/project/${projectId}/finish-schedule/picklist/${currentPicklist.id}/add-list-link/${picklist.id}`,
    );
  }

  removeListLink(
    projectId: number,
    currentPicklist: Picklist,
  ): Observable<{ from: Picklist; to: Picklist }> {
    this.onBeginUpdate$.next();
    return this.http.get<{ from: Picklist; to: Picklist }>(
      `/api/project/${projectId}/finish-schedule/picklist/${currentPicklist.id}/remove-list-link`,
    );
  }

  sortRoomAndFloor(
    projectId: number,
    targets: FloorGroup[],
    roomOrFloorId: number,
    isFloor: boolean,
  ): Observable<undefined> {
    this.onBeginUpdate$.next();
    return this.http.post<undefined>(
      `/api/project/${projectId}/finish-schedule/sort-room-and-floor`,
      { roomOrFloorId, isFloor, floorGroups: targets },
    );
  }

  sortExteriorCategoryAndSet(
    projectId: number,
    targets: ExteriorCategory[],
    categoryOrSetId: number,
    isCategory: boolean,
  ): Observable<undefined> {
    this.onBeginUpdate$.next();
    return this.http.post<undefined>(
      `/api/project/${projectId}/finish-schedule/sort-exterior-category-and-set`,
      { categoryOrSetId, isCategory, exteriorCategories: targets },
    );
  }

  saveExteriorSet(
    projectId: number,
    target: ExteriorSet,
  ): Observable<ExteriorSet> {
    this.onBeginUpdate$.next();
    return this.http.post<ExteriorSet>(
      `/api/project/${projectId}/finish-schedule/exterior-set/${target.id}`,
      target,
    );
  }

  saveExteriorSets(
    projectId: number,
    target: ExteriorSet[],
  ): Observable<ExteriorSet[]> {
    this.onBeginUpdate$.next();
    return this.http.post<ExteriorSet[]>(
      `/api/project/${projectId}/finish-schedule/exterior-sets`,
      target,
    );
  }

  saveRoom(projectId: number, target: RoomGroup): Observable<RoomGroup> {
    this.onBeginUpdate$.next();
    return this.http.post<RoomGroup>(
      `/api/project/${projectId}/finish-schedule/room-group/${target.id}`,
      target,
    );
  }

  deleteRoom(projectId: number, target: RoomGroup): Observable<undefined> {
    this.onBeginUpdate$.next();
    return this.http.delete<undefined>(
      `/api/project/${projectId}/finish-schedule/room-group/${target.id}`,
    );
  }

  deleteExteriorSet(
    projectId: number,
    target: ExteriorSet,
  ): Observable<undefined> {
    this.onBeginUpdate$.next();
    return this.http.delete<undefined>(
      `/api/project/${projectId}/finish-schedule/exterior-set/${target.id}`,
    );
  }

  saveExteriorCategory(
    projectId: number,
    target: ExteriorCategory,
  ): Observable<ExteriorCategory> {
    this.onBeginUpdate$.next();
    return this.http.post<ExteriorCategory>(
      `/api/project/${projectId}/finish-schedule/exterior-category/${target.id}`,
      target,
    );
  }

  deletePicklist(projectId: number, target: Picklist): Observable<undefined> {
    this.onBeginUpdate$.next();
    return this.http.delete<undefined>(
      `/api/project/${projectId}/finish-schedule/picklist/${target.id}`,
    );
  }

  removePicklistFromParts(
    projectId: number,
    picklist: Picklist,
    parts: InteriorSetParts,
  ): Observable<undefined> {
    this.onBeginUpdate$.next();
    return this.http.get<undefined>(
      `/api/project/${projectId}/finish-schedule/picklist/${picklist.id}/remove-from/interior-parts/${parts.id}`,
    );
  }

  removePicklistFromExteriorSet(
    projectId: number,
    picklist: Picklist,
    set: ExteriorSet,
  ): Observable<undefined> {
    this.onBeginUpdate$.next();
    return this.http.get<undefined>(
      `/api/project/${projectId}/finish-schedule/picklist/${picklist.id}/remove-from/exterior-set/${set.id}`,
    );
  }

  tags(projectId: number, picklistId: number): Observable<string[]> {
    this.onBeginUpdate$.next();
    return this.http.get<string[]>(
      `/api/project/${projectId}/finish-schedule/picklist/${picklistId}/tags`,
    );
  }

  uploadPerspectiveImage(
    projectId: number,
    roomGroup: RoomGroup,
    file: File,
  ): Observable<{ filePath: string }> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    this.onBeginUpdate$.next();
    return this.http.post<{ filePath: string }>(
      `/api/project/${projectId}/finish-schedule/room-group/${roomGroup.id}/perspective-image`,
      formData,
    );
  }

  uploadConstructionDocument(
    projectId: number,
    roomGroup: RoomGroup,
    file: File,
  ): Observable<{ sourceName: string; filePath: string }> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    this.onBeginUpdate$.next();
    return this.http.post<{ sourceName: string; filePath: string }>(
      `/api/project/${projectId}/finish-schedule/room-group/${roomGroup.id}/construction-document`,
      formData,
    );
  }

  saveRoomsInGroup(rooms: Room[]) {
    this.onBeginUpdate$.next();
    return this.http.post<any>(
      `/api/project/${rooms.first()!.project_id}/finish-schedule/room/many`,
      { rooms },
    );
  }

  duplicateRoomGroup(roomGroup: RoomGroup): Observable<{
    roomGroup: RoomGroup;
    set: InteriorSet;
    roomBlocks: RoomBlock[];
  }> {
    this.onBeginUpdate$.next();
    return this.http.post<{
      roomGroup: RoomGroup;
      set: InteriorSet;
      roomBlocks: RoomBlock[];
    }>(
      `/api/project/${roomGroup.project_id}/finish-schedule/room-group/${roomGroup.id}/duplicate`,
      { roomGroup },
    );
  }

  duplicateSet(exteriorSet: ExteriorSet): Observable<ExteriorSet> {
    this.onBeginUpdate$.next();
    return this.http.post<ExteriorSet>(
      `/api/project/${exteriorSet.project_id}/finish-schedule/exterior-set/${exteriorSet.id}/duplicate`,
      exteriorSet,
    );
  }

  validateImportRevitData(projectId: number, file: File, userId: string) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('userId', userId);

    this.onBeginUpdate$.next();
    return this.http.post<{
      [error: string]: { message: string; trace?: string };
    }>(`/api/project/${projectId}/revit/validate`, formData);
  }

  importRevitData(projectId: number, file: File, userId: string) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('userId', userId);

    this.onBeginUpdate$.next();
    return this.http.post<ImportBimResult>(
      `/api/project/${projectId}/revit/import`,
      formData,
    );
  }

  importAhDynamo(projectId: number, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    this.onBeginUpdate$.next();
    return this.http.post<ImportBimResult>(
      `/api/project/${projectId}/revit/ah/dynamo/import`,
      formData,
    );
  }

  importAhArex(projectId: number, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    this.onBeginUpdate$.next();
    return this.http.post<ImportBimResult>(
      `/api/project/${projectId}/revit/ah/arex/import`,
      formData,
    );
  }

  importBiData(projectId: number, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    this.onBeginUpdate$.next();
    return this.http.post<ImportBimResult>(
      `/api/project/${projectId}/archicad/bi/import`,
      formData,
    );
  }

  importTrussBimData(projectId: number, file: File, userId: string) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('userId', userId);

    this.onBeginUpdate$.next();
    return this.http.post<ImportBimResult>(
      `/api/project/${projectId}/truss-bim/import`,
      formData,
    );
  }

  validateExportRevitData(projectId: number) {
    return this.http.get<
      | { target: string; message: string }[]
      | { error: { message: string; trace?: string } }
    >(`/api/project/${projectId}/revit/validate`);
  }

  exportRevitData(projectId: number) {
    return this.http.get<ExportBimResult>(
      `/api/project/${projectId}/revit/export`,
    );
  }

  logs(
    projectId: number,
    logFilterParams: LogFilterParams,
    page?: number,
    pageSize?: number,
    sortType: ListLogType | string = '',
    isWithPaginate = true,
  ): Observable<Pagination<ListLog> | ListLog[]> {
    page = page || 1;
    pageSize = pageSize || 50;

    let param: HttpParams = new HttpParams()
      .set('pageSize', pageSize.toString())
      .set('sortType', sortType)
      .set('isWithPaginate', isWithPaginate.toString());

    const searchParams: { [key: string]: any } = logFilterParams;
    Object.keys(searchParams).forEach((key) => {
      const value = searchParams[key];
      param = param.append(
        `${key}`,
        value instanceof Array ? value.join(',') : value,
      );
    });

    return this.http.get<Pagination<ListLog> | ListLog[]>(
      `/api/project/${projectId}/logs?page=${page}`,
      { params: param },
    );
  }

  requestBackup(projectId: number, userId: number, type: number) {
    this.onBeginUpdate$.next();
    return this.http.post(`/api/project/${projectId}/requestBackUp`, {
      projectId,
      userId,
      type,
    });
  }

  restoreProject(backupId: number | null, projectId: number, userId: number) {
    this.onBeginUpdate$.next();
    return this.http.post(`/api/project/${projectId}/requestRestore`, {
      backupId,
      projectId,
      userId,
    });
  }

  getCurrentManualBackUpNumber(projectId: number) {
    this.onBeginUpdate$.next();
    return this.http.get(
      `/api/project/${projectId}/current-manual-backup-number`,
    );
  }

  copyProductInfo(
    projectId: number,
    from: Picklist,
    to: Picklist,
  ): Observable<Picklist> {
    this.onBeginUpdate$.next();
    return this.http.get<Picklist>(
      `/api/project/${projectId}/finish-schedule/picklist/${to.id}/copy-product-from/${from.id}`,
    );
  }

  duplicateProject(
    project: Project,
    info: DuplicateProjectResult,
  ): Observable<Project> {
    this.onBeginUpdate$.next();
    return this.http.post<Project>(`/api/project/duplicate`, { project, info });
  }

  generateColorImage(
    projectId: number,
    picklistId: number,
    color: ColorImage,
  ): Observable<ColorImage> {
    return this.http.post<ColorImage>(
      `/api/project/${projectId}/finish-schedule/picklist/${picklistId}/color`,
      color,
    );
  }

  setMultiplePicklistsToParts(
    projectId: number,
    picklists: Picklist[],
    target: { room: RoomGroup; category: InteriorCategory }[],
  ): Observable<any> {
    this.onBeginUpdate$.next();
    return this.http.post<any>(
      `/api/project/${projectId}/finish-schedule/picklist/multiple-set`,
      { picklists, target },
    );
  }

  removeMultiplePicklistsFromParts(
    projectId: number,
    param: {
      picklists: Picklist[];
      target: InteriorSetParts;
    }[],
  ): Observable<any> {
    this.onBeginUpdate$.next();
    return this.http.post<any>(
      `/api/project/${projectId}/finish-schedule/picklist/multiple-remove`,
      { param },
    );
  }

  changeListProductStatus(projectId: number, from: string, to: string) {
    return this.http.put<any>(
      `/api/project/${projectId}/changeListProductStatus`,
      { from, to },
    );
  }

  getJobPermission(): Observable<{
    [key: string]: { [key: string]: boolean };
  }> {
    return this.http.get<{ [key: string]: { [key: string]: boolean } }>(
      '/api/project/jobPermission',
    );
  }

  createOtherProjectType(
    project: Project,
    info: DuplicateProjectResult,
    isUpdateTemplate = false,
    projectType = ProjectType.Template,
  ): Observable<Project> {
    this.onBeginUpdate$.next();
    const isCreateTemplate = true;
    return this.http.post<Project>(`/api/project/createOtherProjectType`, {
      project,
      info,
      isCreateTemplate,
      isUpdateTemplate,
      projectType,
    });
  }

  deleteProject(project: Project): Observable<Project> {
    return this.http.delete<Project>(`/api/project/${project.id}`);
  }

  countListStatusPerType(
    projectId: number,
    status: PicklistProductStatus,
  ): Observable<{ interior: number; exterior: number; outdoor: number }> {
    const params = new HttpParams().set('status', status);
    return this.http.get<{
      interior: number;
      exterior: number;
      outdoor: number;
    }>(`/api/project/${projectId}/countListStatusPerType`, {
      params: params,
    });
  }
  // プロジェクト検索 API 呼出し
  search(
    queryParams: ProjectSearchParams,
    page = 1,
  ): Observable<Pagination<ProjectSearchInfo> | ProjectSearchInfo[]> {
    const params = this.converter.convert(queryParams); // クエリパラメータ生成
    return this.http.get<Pagination<ProjectSearchInfo>>(
      `/api/project/search?page=${page}`,
      { params },
    );
  }
  // プロジェクト共有申請 API 呼出し
  shareRequest(created_id: any, project_id: number) {
    return this.http.post<any>(`/api/project/request/share`, {
      created_id,
      project_id,
    });
  }

  monitorChanges(
    projectId: number,
  ): Observable<
    Pick<
      Project,
      | 'id'
      | 'updated_at'
      | 'latestListLog'
      | 'job_type'
      | 'can_edit'
      | 'can_approve'
      | 'need_notification'
      | 'can_request'
    >
  > {
    return this.http.get<
      Pick<
        Project,
        | 'id'
        | 'updated_at'
        | 'latestListLog'
        | 'job_type'
        | 'can_edit'
        | 'can_approve'
        | 'need_notification'
        | 'can_request'
      >
    >(`/api/project/${projectId}/monitorChanges`);
  }

  getSamples(): Observable<Project[]> {
    return this.http.get<Project[]>('/api/project/samples');
  }

  checkFromRevit(): Observable<boolean> {
    return this.http
      .get<{ hasRevit: boolean }>('/api/project/revit/check-from-revit')
      .pipe(map((value) => value.hasRevit));
  }

  getProjectList(): Observable<any> {
    return this.http.get<any>('/api/users/get-project-list');
  }
}
