import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FeatureKey } from '../../enums/feature-key.enum';
import {
  JoineryPageInfoAdapter,
  JoineryPageInfoState,
} from '../joinery-page-info.state';

const getJoineryPageInfoState = createFeatureSelector<JoineryPageInfoState>(
  FeatureKey.JoineryPageInfo,
);

const { selectAll } = JoineryPageInfoAdapter.getSelectors();

export const getIsFetching = createSelector(
  getJoineryPageInfoState,
  (state) => state.isFetching,
);

export const getError = createSelector(
  getJoineryPageInfoState,
  (state) => state.error,
);

export const getJoineryPageInfo = createSelector(
  getJoineryPageInfoState,
  (state) => selectAll(state).first(),
);

export const getSymbolOptions = createSelector(
  getJoineryPageInfoState,
  (state) => selectAll(state).first()?.joinery_symbol_options,
);

export const getStandardComponents = createSelector(
  getJoineryPageInfoState,
  (state) => selectAll(state).first()?.standard_components,
);
