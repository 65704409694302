import { Injectable } from '@angular/core';
import { Event } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { BehaviorSubject, Observable } from 'rxjs';

type PageTypeState = {
  activePage: ProjectPageType | null;
};

@Injectable({
  providedIn: 'root',
})
export class ProjectPageStore extends ComponentStore<PageTypeState> {
  routerEventSubject$ = new BehaviorSubject<Event | undefined>(undefined);
  routerEvent$ = this.routerEventSubject$.asObservable();

  constructor() {
    super({
      activePage: null,
    });
  }

  activePage$: Observable<ProjectPageType | null> = this.select((state) => {
    return state.activePage;
  });
}

export enum ProjectPageType {
  FinishSchedule = 'finish-schedule',
  Picklist = 'picklist',
  PicklistTable = 'picklist-table',
  MaterialBoard = 'material-board',
  Info = 'info',
  Log = 'log',
  Member = 'member',
  SampleRequestHistory = 'sample-request-history',
  ShippingAddress = 'shipping-address',
  Window = 'window-frame',
  Door = 'door-frame',
  Shutter = 'shutter',
  JoineryOptionSettings = 'joinery-option-settings',
  SearchFromProject = 'search-from-project',
  RoomGroupList = 'room-group-list',
  SpecNameList = 'spec-name-list',
}

export const getProjectPageName = (type: ProjectPageType | string) => {
  switch (type) {
    case ProjectPageType.FinishSchedule:
      return '仕上表';
    case ProjectPageType.PicklistTable:
      return '材料表';
    case ProjectPageType.MaterialBoard:
      return 'マテリアルボード';
    case ProjectPageType.Window:
      return '窓';
    case ProjectPageType.Door:
      return 'ドア';
    case ProjectPageType.Shutter:
      return 'シャッター';
    case ProjectPageType.JoineryOptionSettings:
      return '凡例設定';

    case ProjectPageType.Info:
      return 'プロジェクト情報';
    case ProjectPageType.Member:
      return 'メンバー管理';
    case ProjectPageType.Log:
      return '操作履歴';
    case ProjectPageType.SampleRequestHistory:
      return 'サンプル請求履歴';
    case ProjectPageType.ShippingAddress:
      return 'お届け先';

    case ProjectPageType.RoomGroupList:
      return '部屋一覧';
    case ProjectPageType.SpecNameList:
      return '仕様一覧';

    default:
      return '';
  }
};
