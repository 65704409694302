import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  DragStartDelay,
} from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  NgZone,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Group } from 'app/v2/general/domain/enums/group.enum';
import { first, takeUntil } from 'rxjs/operators';

import { ProjectPageService } from '../../../general/project/project-page.service';
import { List } from '../../models/list';
import {
  ConstructionClassificationKeys,
  Room,
  RoomInfoKeys,
} from '../../models/response/room';
import { RoomGroup } from '../../models/response/room-group';
import { ManageBimRoomInfoDialogComponent } from '../manage-bim-room-info-dialog/manage-bim-room-info-dialog.component';

@Component({
  selector: 'ls-manage-bim-room-dialog',
  templateUrl: './manage-bim-room-dialog.component.html',
  styleUrls: ['./manage-bim-room-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class ManageBimRoomDialogComponent implements OnDestroy {
  selectedRooms: {
    [key: string]: boolean;
  } = {};

  selectAll = false;
  flIs?: string | false = false;
  nameLike: string;
  hidePlaceholder = false;

  targetRooms: Room[] = [];
  currentRoom?: Room;

  onDestroy$ = new EventEmitter();

  get finishSchedule() {
    return this.projectPage.finishSchedule!;
  }

  get isSelectedAll() {
    return (
      Object.keys(this.selectedRooms).filter((id) => this.selectedRooms[id])
        .length === this._notGroupedRooms!.length
    );
  }

  get fls() {
    return this.getGroupedRooms()
      .map((r) => r.level)
      .unique();
  }

  get notGroupedRooms() {
    if (!this._notGroupedRooms) {
      this._notGroupedRooms = this.getGroupedRooms();

      if (this.flIs)
        this._notGroupedRooms = this._notGroupedRooms.filter(
          (room) => room.level === this.flIs,
        );

      if (this.nameLike)
        this._notGroupedRooms = this._notGroupedRooms.filter((room) =>
          room.name.match(new RegExp(`.*${this.nameLike}.*`)),
        );

      const ids = this._notGroupedRooms.map((room) => room.id.toString());

      this.selectedRooms = Object.keys(this.selectedRooms)
        .filter((id) => ids.indexOf(id) > -1)
        .reduce(
          (result, id) => {
            result[id] = this.selectedRooms[id];
            return result;
          },
          {} as { [key: string]: boolean },
        );

      this.selectAll = this.isSelectedAll;
    }
    return this._notGroupedRooms;
  }
  _notGroupedRooms?: Room[];

  get badgeCount() {
    return this.targetRooms.length > 1 ? this.targetRooms.length : undefined;
  }

  get projectPage() {
    return this.data.projectPage;
  }

  getDelayed(): DragStartDelay {
    return this.projectPage.getDelayed();
  }

  @ViewChildren(CdkDropList)
  set cdkDropLists(dropLists: QueryList<CdkDropList<RoomGroup | undefined>>) {
    dropLists.forEach((d) => {
      d.entered.pipe(takeUntil(this.onDestroy$)).subscribe((_) => {
        this.hidePlaceholder = !d.data;
      });
    });
    this._dropLists = dropLists.toArray();
  }
  _dropLists: CdkDropList<RoomGroup | undefined>[];

  get dropLists() {
    return this._dropLists;
  }

  @ViewChildren(CdkDrag)
  set drags(drags: QueryList<CdkDrag<Room>>) {
    drags.forEach((d) => {
      d.started.pipe(takeUntil(this.onDestroy$)).subscribe((_) => {
        this.hidePlaceholder = true;
        this.targetRooms = [
          d.data,
          ...this.notGroupedRooms.filter(
            (r) =>
              Object.keys(this.selectedRooms)
                .filter((id) => !!this.selectedRooms[id])
                .indexOf(r.id.toString()) > -1,
          ),
        ].unique();
        this.currentRoom = d.data;
      });
    });
  }

  constructor(
    private dialogRef: MatDialogRef<ManageBimRoomDialogComponent>,
    private changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data: { projectPage: ProjectPageService },
    private zone: NgZone,
    private dialog: MatDialog,
  ) {}

  ngOnDestroy() {
    this.onDestroy$.emit();
  }

  cancel() {
    this.dialogRef.close();
  }

  onChangeSelectAll() {
    this.selectedRooms = this.notGroupedRooms.reduce(
      (result, room) => {
        result[room.id.toString()] = this.selectAll;
        return result;
      },
      {} as { [key: string]: boolean },
    );
  }

  getGroupedRooms(groupId?: number) {
    return this.finishSchedule.rooms.filter((r) => {
      return r.room_group_id == groupId;
    });
  }

  updateNotGroupedRooms() {
    this._notGroupedRooms = undefined;
    return this.notGroupedRooms;
  }

  addRoom() {
    this.projectPage.addRoom().subscribe((_) => {
      // ↓ドロップ領域をルームが増えた状態で更新させるための処理
      // 部屋追加の処理が終わったら一旦描画させる
      this.changeDetector.markForCheck();
      // 追加された状態でCdkDropListなどが描画され終わったタイミングでViewChildrenのsetterを走らせるためにmarkForCheck()
      this.zone.onStable
        .pipe(takeUntil(this.onDestroy$), first())
        .subscribe((_) => {
          this.changeDetector.markForCheck();
        });
    });
  }

  saveRoomInGroup(room: Room, groupId?: number) {
    this.projectPage.saveRoomsInGroup([room], groupId);
    this.updateNotGroupedRooms();
  }

  setRoomToGroup(event: CdkDragDrop<RoomGroup>) {
    if (this.finishSchedule.group !== Group.AH) {
      const prepareRooms = [
        ...this.getGroupedRooms(event.container.data.id),
        ...this.targetRooms,
      ];
      const matched = this.isMatchInfo(prepareRooms);
      if (matched) {
        this.copyRoomInfo(
          prepareRooms.first()!,
          event.container.data,
          prepareRooms,
        );
        this._setRoomToGroup(event, this.targetRooms);
      } else {
        this.dialog
          .open(ManageBimRoomInfoDialogComponent, { data: { prepareRooms } })
          .afterClosed()
          .subscribe((r) => {
            if (r) {
              this.copyRoomInfo(r, event.container.data, prepareRooms);
              this._setRoomToGroup(event, prepareRooms);
            } else {
              this.cancelDrag(event);
            }
            this.changeDetector.markForCheck();
          });
      }
    } else {
      this._setRoomToGroup(event, this.targetRooms);
    }
  }
  isMatchInfo(rooms: Room[]) {
    let isUnmatch = false;
    const base = rooms.first()!;

    for (const room of rooms) {
      for (const k of RoomInfoKeys) {
        if (base[k] != room[k]) {
          isUnmatch = true;
          break;
        }
      }

      if (
        !!base.construction_classifications !=
        !!room.construction_classifications
      ) {
        isUnmatch = true;
        break;
      }

      if (
        !!base.construction_classifications &&
        !!room.construction_classifications
      ) {
        for (const k of ConstructionClassificationKeys) {
          if (
            base.construction_classifications[k] !=
            room.construction_classifications[k]
          ) {
            isUnmatch = true;
            break;
          }
        }

        if (isUnmatch) break;
      }
    }

    return !isUnmatch;
  }

  copyRoomInfo(base: Room, roomGroup: RoomGroup, rooms: Room[]) {
    const classifications: List<string> = {};
    ConstructionClassificationKeys.forEach((k) => {
      classifications[k] = base.construction_classifications
        ? base.construction_classifications[k] || ''
        : '';
      if (!classifications[k]) {
        roomGroup.construction_classifications
          ? roomGroup.construction_classifications[k] || ''
          : '';
      }
    });

    for (const room of [roomGroup, ...rooms]) {
      for (const k of RoomInfoKeys) {
        base[k] = base[k] || roomGroup[k];
      }
      for (const k of RoomInfoKeys) {
        room[k] = base[k];
      }
      room.construction_classifications = classifications;
    }
  }

  _setRoomToGroup(event: CdkDragDrop<RoomGroup>, rooms: Room[]) {
    this.projectPage.saveRoomsInGroup(rooms, event.container.data.id);
    this.hidePlaceholder = false;
    this.targetRooms = [];
    this.currentRoom = undefined;
    this.updateNotGroupedRooms();
  }
  cancelDrag(event: CdkDragDrop<RoomGroup>) {
    this.hidePlaceholder = false;
    this.targetRooms = [];
    this.currentRoom = undefined;
  }

  disableDrop(item: CdkDrag<Room>, to: CdkDropList<RoomGroup>) {
    return false;
  }
}
