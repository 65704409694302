// eslint-disable-next-line no-var, @typescript-eslint/no-explicit-any
declare var gtag: any;

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { environment } from '@environments/environment';
import { User } from '@shared/models/response/user';
import { forkJoin, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  skip,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { UserService } from './shared/service/user.service';
import { UserApi } from './v2/_api/user.api';
import { ProjectStore } from './v2/general/application/store/project.store';
import { UserStore } from './v2/general/core/store/user.store';

@Component({
  selector: 'ls-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private userService = inject(UserService);
  private userApi = inject(UserApi);
  private userStore = inject(UserStore);
  private projectStore = inject(ProjectStore);

  private readonly onDestroy$ = new Subject<void>();

  protected userIsLoading$ = this.userStore.isLoading$;

  ngOnInit(): void {
    this.fetchUserAndProjectsData();
    this.handleUserIdChange();
    this.updateGoogleAnalytics();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // ユーザー情報とプロジェクト情報を取得
  private fetchUserAndProjectsData(): void {
    this.userApi
      .me()
      .pipe(
        takeUntil(this.onDestroy$),
        tap((user: User | undefined) => {
          this.userStore.patchState({ isLoading: true });

          if (!user) {
            // 未登録ユーザーの場合、ここで終了
            this.userStore.patchState({ isLoading: false });
            this.setupGoogleAnalytics(undefined);
          }

          this.userStore.patchState({ user, isLoading: false });
          this.userService.currentUserDataSource.next(user);
        }),
        filter((user: User | undefined) => !!user),
        switchMap((user: User) => {
          if (user.is_enable_project) {
            // 有料ユーザーの場合、追加データを取得して返却
            this.projectStore.patchState({ isLoading: true });
            return forkJoin([
              of(user),
              this.userApi.getEnableProjectUser(user.id),
              this.userApi.getProjects(user.id, { order: 'desc' }),
            ]).pipe(
              map(([user, paidUserData, projectsData]) => ({
                ...user,
                ...paidUserData,
                ...projectsData,
              })),
            );
          } else {
            // 無料ユーザーの場合、そのまま返却
            return of(structuredClone(user));
          }
        }),
      )
      .subscribe((user: User) => {
        this.userStore.patchState({ user, isLoading: false });
        this.userService.currentUserDataSource.next(user);

        if (user.is_enable_project) {
          // 有料ユーザーの場合、ここでユーザーデータをセット
          this.projectStore.patchState({
            projects: user.projects,
            projectsCount: user.projects?.length || 0,
            isLoading: false,
          });
        }

        // 無料、有料問わず、ここでGAの設定を行う
        this.setupGoogleAnalytics(user);
      });
  }

  // ユーザーIDが変更された際の処理
  private handleUserIdChange(): void {
    this.userStore.userId$
      .pipe(
        skip(2), // 初期値のundefinedと、初回のユーザー情報取得時のIDをスキップ
        distinctUntilChanged((prevId, currId) => prevId === currId), // 前回と同じIDの場合はスキップ
      )
      .subscribe((userId) => {
        if (userId) {
          alert('ログインユーザーが変わりました。画面を再読み込みします。');
          location.reload();
        } else {
          alert('ログアウトしました。ログインページへ移動します。');
          const redirect = encodeURIComponent(location.href);
          location.href = `/user/login?r=${redirect}`;
        }
      });
  }

  // Google Analyticsの設定
  private setupGoogleAnalytics(user: User | undefined): void {
    if (user && user.id) {
      const tagId = environment.gaTrackingId;
      gtag('config', tagId, { user_id: user.id });
    }

    gtag('set', 'user_properties', {
      paid: user ? user.is_enable_project : false,
      email: user ? user.email.split('@')[1] : 'none',
      job_type: user ? user.job : 'none',
      company_name: user ? user.companyName : 'none',
      department: user ? user.department : 'none',
      address_level_1: user ? user.address_level_1 ?? '' : 'none',
      address_level_2: user ? user.address_level_2 ?? '' : 'none',
      address_line_1: user ? user.address_line_1 ?? '' : 'none',
    });
  }

  // ユーザーのデータが更新されたらGoogle Analyticsのユーザープロパティも更新
  private updateGoogleAnalytics(): void {
    this.userStore.user$
      .pipe(
        takeUntil(this.onDestroy$),
        filter((user: User | undefined) => !!user),
        skip(2), // Meの取得とswitchMapの結果をスキップ
      )
      .subscribe((user: User) => {
        this.setupGoogleAnalytics(user);
      });
  }
}
