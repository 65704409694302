import { Injectable } from '@angular/core';
import { ApiService } from '@shared/api/api.service';
import { UserGroupType } from '@shared/models/response/sub/user-group-type';
import { User } from '@shared/models/response/user';
import {
  Group,
  getGroups,
  GroupSetting,
  getGroupSetting,
} from 'app/v2/general/domain/enums/group.enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { skip, switchMap, tap } from 'rxjs/operators';

import { UserService as ApiUserService } from '../api/user.service';
import { FavoriteList } from '../models/response/favorite-list';
import { KpiParam } from '../models/response/kpi-param';
import { Project } from '../models/response/project';
import { ProjectType } from '../models/response/sub/project-type';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private isLoginedDataSource = new BehaviorSubject<boolean>(false);
  readonly isLogined = this.isLoginedDataSource.asObservable();

  private isAdminDataSource = new BehaviorSubject<boolean>(false);
  readonly isAdmin = this.isAdminDataSource.asObservable();

  public currentUserIdDataSource = new BehaviorSubject<string | undefined>(
    undefined,
  );
  readonly currentUserId = this.currentUserIdDataSource.asObservable();
  readonly userIdChanged = this.currentUserId.pipe(skip(2));

  user$: Observable<User | undefined> = this.api.user.me();
  public currentUserDataSource = new BehaviorSubject<User | undefined>(
    undefined,
  );
  readonly currentUser: Observable<User | undefined> =
    this.currentUserDataSource.asObservable();

  readonly useBootoneDataSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  useBootone$: Observable<boolean> = this.useBootoneDataSource.asObservable();
  useBootoneGroups$: Observable<Group[]> = this.useBootone$.pipe(
    switchMap((useBootone) => {
      if (useBootone && this.currentUserData.groups) {
        return of(this.currentUserData.groups.map((v) => v.group));
      } else {
        return of([]);
      }
    }),
  );

  readonly useBiDataSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  useBi$: Observable<boolean> = this.useBiDataSource.asObservable();
  useBiGroups$: Observable<Group[]> = this.useBi$.pipe(
    switchMap((useBi) => {
      if (useBi && this.currentUserData.groups) {
        return of(this.currentUserData.groups.map((v) => v.group));
      } else {
        return of([]);
      }
    }),
  );

  readonly useBimDataSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  useBim$: Observable<boolean> = this.useBimDataSource.asObservable();
  useBimGroups$: Observable<Group[]> = this.useBim$.pipe(
    switchMap((useBim) => {
      if (useBim && this.currentUserData && this.currentUserData.groups) {
        const bimGroup = Array.from(
          new Set([
            ...this.bimPermissionGroups,
            ...this.currentUserData.groups.map((v) => v.group),
          ]),
        );
        return of(bimGroup);
      } else {
        return of(this.bimPermissionGroups);
      }
    }),
  );
  bimPermissionGroups: Group[] = [
    Group.Truss,
    Group.Daiwa,
    Group.Cigr,
    Group.DiceNext,
    Group.Fujita,
    Group.DaiwaLease,
    Group.Taisei,
    Group.Nishimatsu,
    Group.MitsuiSumitomo,
    Group.Meikou,
    Group.Goyou,
  ];

  _getGroups = getGroups();

  constructor(
    private apiUser: ApiUserService,
    private api: ApiService,
  ) {}

  get fullName() {
    return `${this.currentUserData.last_name}${this.currentUserData.first_name}`;
  }

  get onLoad() {
    return this.currentUserDataSource;
  }

  get currentUserData(): User {
    return this.currentUserDataSource.value!;
  }

  get hasCurrentUserData(): boolean {
    return !!this.currentUserData;
  }

  get hasEnableProjectUserData(): boolean {
    return (
      this.hasCurrentUserData &&
      !!this.currentUserData!.projects &&
      !!this.currentUserData!.groups
    );
  }

  get canCreateSample() {
    return this.hasEnableProjectUserData
      ? this.currentUserData.is_create_sample
      : false;
  }

  get canCreateStandardSetProject() {
    return this.hasEnableProjectUserData
      ? this.currentUserData.can_create_standard_set_project
      : false;
  }

  get groups() {
    if (this.hasEnableProjectUserData) {
      return this.currentUserData!.groups;
    }
    return [];
  }

  get isEmployee(): boolean {
    if (this.hasEnableProjectUserData) {
      return (
        this.groups!.filter((g) => g.user_group_type == UserGroupType.Employee)
          .length > 0
      );
    }

    return false;
  }

  get isPartner(): boolean {
    if (this.hasEnableProjectUserData) {
      return (
        this.groups!.filter((g) => g.user_group_type == UserGroupType.Partner)
          .length > 0
      );
    }

    return false;
  }

  get canCreateProject(): boolean {
    return this.isEmployee || this.isPartner;
  }

  get projects(): Project[] {
    return this.hasEnableProjectUserData
      ? this.canCreateSample
        ? this.currentUserData!.projects?.concat(
            this.templates,
            this.samples,
          ) || []
        : this.currentUserData!.projects?.concat(this.templates) || []
      : [];
  }

  get onlyProjects(): Project[] {
    return this.hasEnableProjectUserData
      ? this.currentUserData!.projects?.sort((a, b) => {
          return a.updated_at > b.updated_at ? -1 : 1;
        }) || []
      : [];
  }

  set onlyProjects(_projects: Project[]) {
    if (this.hasEnableProjectUserData) {
      this.currentUserData!.projects = _projects;
    }
  }

  get templates(): Project[] {
    return this.hasEnableProjectUserData
      ? this.currentUserData!.templates || []
      : [];
  }

  set templates(_templates: Project[]) {
    if (this.hasEnableProjectUserData) {
      this.currentUserData!.templates = _templates;
    }
  }

  get samples() {
    return this.hasEnableProjectUserData && this.canCreateSample
      ? this.currentUserData!.samples || []
      : [];
  }

  set samples(_samples: Project[]) {
    if (this.hasEnableProjectUserData && this.canCreateSample) {
      this.currentUserData!.samples = _samples;
    }
  }

  get standardSetProjects() {
    return this.hasEnableProjectUserData && this.canCreateStandardSetProject
      ? this.currentUserData!.standard_sets || []
      : [];
  }

  set standardSetProjects(_standardSetProjects: Project[]) {
    if (this.hasEnableProjectUserData && this.canCreateStandardSetProject) {
      this.currentUserData!.standard_sets = _standardSetProjects;
    }
  }

  get isDHGroup(): Group[] {
    return [Group.Daiwa, Group.Truss];
  }

  get useDrexAPI(): Group[] {
    return [Group.Daiwa];
  }

  get nonAH(): Group[] {
    return this._getGroups.filter((g) => g !== Group.AH);
  }

  get useFixtures(): Group[] {
    return [Group.Truss, Group.Demo, Group.DemoBootOne];
  }

  getProjectByProjectType(
    type: ProjectType,
    project: Project,
  ): Project | undefined {
    return (() => {
      switch (type) {
        case ProjectType.Sample:
          return this.getSample(project);
        case ProjectType.Template:
          return this.getTemplate(project);
        case ProjectType.StandardSet:
          return this.getStandardSetProject(project);
        default:
          return undefined;
      }
    })();
  }

  removeProjectByProjectType(type: ProjectType, target: Project): void {
    switch (type) {
      case ProjectType.Template:
        this.templates = this.templates.filter(
          (t) => t.source_project_id !== target.id,
        );
        break;
      case ProjectType.Sample:
        this.samples = this.samples.filter(
          (s) => s.source_project_id !== target.id,
        );
        break;

      case ProjectType.StandardSet:
        this.standardSetProjects = this.standardSetProjects.filter(
          (s) => s.source_project_id !== target.id,
        );
        break;

      default:
        break;
    }
  }

  addProjectByProjectType(type: ProjectType, target: Project): void {
    switch (type) {
      case ProjectType.Template:
        this.templates.push(target);
        break;
      case ProjectType.Sample:
        this.samples.push(target);
        break;

      case ProjectType.StandardSet:
        this.standardSetProjects.push(target);
        break;

      default:
        break;
    }
  }

  // get lists() {
  //   return this.hasEnableProjectUserData ? this.currentUserData.lists : [];
  // }

  public checkLoginStatus(): void {
    const isLogined = document.cookie.indexOf('LOGIN-STATUS') > -1;
    if (isLogined !== this.isLoginedDataSource.value) {
      this.isLoginedDataSource.next(isLogined);
    }

    const isAdmin = document.cookie.indexOf('ADMIN') > -1;
    if (isAdmin !== this.isAdminDataSource.value) {
      this.isAdminDataSource.next(isAdmin);
    }
  }

  public setCurrentUserId(id: string | undefined): void {
    if (this.currentUserIdDataSource.value === id) {
      return;
    }

    this.currentUserIdDataSource.next(id);
  }

  // public reflesh() {
  //   const user$: Observable<User | undefined> = this.currentUserIdDataSource
  //     .value
  //     ? this.apiUser.me()
  //     : of(undefined);
  //   user$.pipe(catchError((_) => of(undefined))).subscribe((user) => {
  //     this.currentUserDataSource.next(user);
  //   });

  //   return this.currentUserDataSource;
  // }

  public addFavoriteList(listInfo: FavoriteList): Observable<any> {
    return this.apiUser.storeFavlList(listInfo);
  }

  getTemplate(project: Project): Project | undefined {
    return this.hasEnableProjectUserData
      ? this.templates.find((t) => t.source_project_id == project.id)
      : undefined;
  }

  checkHasTemplate(project: Project): boolean {
    return !!this.getTemplate(project);
  }

  getSample(project: Project): Project | undefined {
    return this.hasEnableProjectUserData && this.canCreateSample
      ? this.samples.find((t) => t.source_project_id == project.id)
      : undefined;
  }

  getStandardSetProject(project: Project): Project | undefined {
    return this.hasEnableProjectUserData && this.canCreateStandardSetProject
      ? this.standardSetProjects.find((t) => t.source_project_id == project.id)
      : undefined;
  }

  checkHasSample(project: Project): boolean {
    return !!this.getSample(project);
  }

  // get activeProject() {
  //   const user = this.currentUserDataSource.value;
  //   return user && this.hasEnableProjectUserData
  //     ? user.projects
  //         .concat(user.templates)
  //         .filter((p) => p.id == user.activeProjectId)
  //         .first()
  //     : undefined;
  // }

  get canInviteMember(): boolean {
    return this.isEmployee || this.isPartner;
  }

  get OwnGroup(): Group {
    if (this.hasEnableProjectUserData) {
      const g = this.groups!.filter(
        (g) => g.user_group_type == UserGroupType.Employee,
      ).first();

      return g ? (g.group as Group) : Group.None;
    }

    return Group.None;
  }

  /**
   * 2023/06/30 HowToおよびマニュアル表示分け用に追加（DHのパートナーも含める）
   */
  get ownGroupForManual(): Group {
    if (this.hasEnableProjectUserData) {
      const g = this.groups!.filter((g) => {
        if (g.group === Group.Daiwa) {
          return (
            g.user_group_type == UserGroupType.Employee ||
            g.user_group_type == UserGroupType.Partner
          );
        } else {
          return g.user_group_type == UserGroupType.Employee;
        }
      }).first();

      return g ? (g.group as Group) : Group.None;
    }

    return Group.None;
  }

  get groupSetting(): GroupSetting {
    return getGroupSetting(this.OwnGroup);
  }

  get enableList() {
    const user = this.currentUserDataSource.value!;
    if (!user) {
      return false;
    }

    if (user.user_type === 1) {
      return true;
    }

    const groups = user.groups!;
    if (!groups || groups.length === 0) {
      return false;
    }

    if (!this.enableProject) {
      return false;
    }

    return true;
  }

  get enableProject(): boolean {
    const user = this.currentUserData!;
    return !!user && (user.is_enable_project || user.user_type === 1);
  }

  get userId() {
    return (
      this.currentUserData.id || Number(this.currentUserIdDataSource.value)
    );
  }

  // get template() {
  //   if (!this.activeProject) {
  //     return;
  //   }
  //   return this.templates.find(
  //     (t) => t.source_project_id == this.activeProject!.id
  //   );
  // }

  get kpiParams(): KpiParam {
    const user = this.currentUserData!;

    if (this.hasEnableProjectUserData) {
      return user.kpi_params!;
    }

    return {
      isAddLine: false,
      isCreatePicklist: false,
      isRegisterProduct: false,
      isShowManual: false,
      isCreateProject: false,
    };
  }

  // チェックボックス活性化・ボタン制御用の判定
  isViewerOrEmployeeAsCurrentProjectGroup(
    isViewer: boolean, // 閲覧権限有無
    group: Group | undefined, // 現在のプロジェクト
  ): boolean {
    if (isViewer || !group) {
      return false; // 閲覧権限者がメンバの権限を変更させないための判定
    } else {
      // 上記以外は所属グループで判定
      return this.isEmployeeAsCurrentProjectGroup(group);
    }
  }

  isEmployeeAsCurrentProjectGroup(group: Group): boolean {
    if (this.hasEnableProjectUserData && this.groups) {
      return this.groups.some(
        (g) =>
          g.group === group && g.user_group_type === UserGroupType.Employee,
      );
    }
    return false;
  }

  checkAllowedGroupsOnEmployee(groups: string[]): boolean {
    return !!groups.find((g) => g == this.OwnGroup);
  }

  getCurrentUserData() {
    return this.apiUser.me().pipe(
      tap((data) => {
        this.currentUserDataSource.next(data);
      }),
    );
  }
}
