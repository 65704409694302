import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { Picklist } from '../../models/response/picklist';
import { PicklistType } from '../../models/response/sub/picklist-type';
import { ThumbnailType } from '../../models/response/sub/thumbnail-type';

import { AddPicklistResult } from './add-outdoor-picklist-dialog.service';
import { AddPicklistInput } from './add-outdoor-picklist-dialog.service';

@Component({
  selector: 'ls-add-outdoor-picklist-dialog',
  templateUrl: './add-outdoor-picklist-dialog.component.html',
  styleUrls: ['./add-outdoor-picklist-dialog.component.scss'],
})
export class AddOutdoorPicklistDialogComponent implements OnInit {
  get projectPage() {
    return this.data.projectPage;
  }

  get finishSchedule() {
    return this.projectPage.finishSchedule;
  }

  get picklists(): Picklist[] {
    return this.finishSchedule!.picklists.filter(
      (picklist) => picklist.type == PicklistType.Outdoor,
    );
  }

  get inputPicklist(): Picklist {
    return this.data.inputPicklist!;
  }

  get isCopy(): boolean {
    return this.data.isCopy;
  }

  get canSubmit(): boolean {
    return (
      !this.markErrorMessage &&
      !this.nameErrorMessage &&
      !!this.inputData.picklist.mark!.trim() &&
      !!this.inputData.picklist.name.trim()
    );
  }

  get markErrorMessage(): string {
    return this._markErrorMessage;
  }

  set markErrorMessage(message: string) {
    this._markErrorMessage = message;
  }

  get nameErrorMessage(): string {
    return this._nameErrorMessage;
  }

  set nameErrorMessage(message: string) {
    this._nameErrorMessage = message;
  }

  _markErrorMessage = '';
  _nameErrorMessage = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: AddPicklistInput,
    private changeDetector: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AddOutdoorPicklistDialogComponent, any>,
  ) {}

  inputData: AddPicklistResult = {
    picklist: {
      id: 0,
      revit_id: '',
      project_id: 0,
      type: PicklistType.Outdoor,
      name: '',
      mark: '',
      keynote: '',
      surface_pattern_id: null,
      specification: '',
      assign_type: 'input popular name',
      enable_similar: 1,
      similar_memo: '',
      enable_multiple_paste: false,
      remarks: '',
      memo: '',
      product_use_image: ThumbnailType.Product,
      order: {},
      created_at: '',
      updated_at: '',
      is_structural_material: false,
      structural_code: null,
      listProducts: [],
    },
  };

  alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  ngOnInit(): void {
    if (this.inputPicklist) {
      if (this.isCopy) {
        this.inputData.picklist = Object.assign({}, this.inputPicklist);
        this.inputData.picklist.id = 0;
        this.inputData.picklist.mark =
          this.inputData.picklist.mark + 'のコピー';
        this.inputData.picklist.name =
          this.inputData.picklist.name + 'のコピー';
        this.inputData.picklist.revit_id = null;

        this.inputData.copyInfo = {
          needProduct: false,
        };
      } else {
        this.inputData.picklist = this.inputPicklist;
      }
      this.checkMark(this.inputData.picklist.mark!, this.inputPicklist);
      this.checkName(this.inputData.picklist.name, this.inputPicklist);
      this.changeDetector.markForCheck();
    } else {
      this.inputData.picklist.project_id = this.projectPage.projectId;
      if (this.picklists.length == 0) {
        this.inputData.picklist.mark = 'A';
      } else {
        const lastMark: string =
          this.picklists[this.picklists.length - 1].mark!;

        if (lastMark.match(/^[A-Z]$/)) {
          const alphabetNumbers: number[] = [];

          this.picklists!.forEach((picklist) => {
            if (picklist.mark!.match(/^[A-Z]$/)) {
              alphabetNumbers.push(this.alphabet.indexOf(picklist.mark!));
            }
          });

          if (alphabetNumbers.length > 0) {
            let intMax: number = alphabetNumbers[0];

            alphabetNumbers.forEach((alphabetNumber) => {
              if (intMax < alphabetNumber) intMax = alphabetNumber;
            });

            this.inputData.picklist.mark = this.alphabet.charAt(intMax + 1);
          } else {
            this.inputData.picklist.mark = this.alphabet.charAt(
              this.alphabet.indexOf(lastMark) + 1,
            );
          }
        }
      }
    }
  }

  checkMark(mark: string, target: Picklist): void {
    this.markErrorMessage = this.projectPage.getErrorOfPicklistMark(
      target,
      mark,
    );
    this.changeDetector.markForCheck();
  }

  checkName(name: string, target: Picklist): void {
    this.nameErrorMessage = this.projectPage.getErrorOfPicklistName(
      target,
      name,
    );
    this.changeDetector.markForCheck();
  }

  changeKeynote(picklist: Picklist): void {
    this.inputData.picklist = picklist;
    this.checkName(picklist.name, picklist);
    this.changeDetector.markForCheck();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.inputData.copyInfo && !this.inputData.copyInfo!.needProduct) {
      this.inputData.picklist = Object.assign(this.inputData.picklist);
    }
    this.dialogRef.close(this.inputData);
  }
}
