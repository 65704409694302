import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { InteriorSelectNewValueComponent } from '@shared/ui/select/select-new-value/interior/interior-select-new-value.component';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { List } from '../../models/list';
import { RoomGroup } from '../../models/response/room-group';
import { ConstructionClassification } from '../../models/response/sub/show-column';

export interface AddRoomResult {
  floorName: string;
  roomGroups: RoomGroup[];
}

export interface InputValue {
  name: string;
  id?: number;
}

@Component({
  selector: 'ls-add-room-dialog',
  templateUrl: './add-room-dialog.component.html',
  styleUrls: ['./add-room-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class AddRoomDialogComponent implements OnInit, OnDestroy {
  @ViewChild('interiorSelectNewValue')
  interiorSelectNewValue: InteriorSelectNewValueComponent;

  title: string | undefined;
  subtitle: string | undefined;
  inputData: AddRoomResult = {
    floorName: '',
    roomGroups: [],
  };
  roomNames: InputValue[] = [];
  typedValue: string;
  filteredGroup$: Observable<string[]>;
  onDestroy$ = new Subject<void>();

  get canSubmit(): boolean {
    return this.onlyRoom
      ? !!this.roomNames?.length ||
          (!!this.typedValue && this.typedValue.length > 0)
      : (!!this.nameControl.value && !!this.roomNames!.length) ||
          (!!this.typedValue && this.typedValue.length > 0);
  }

  get onlyRoom(): boolean {
    return this.data.mode === 'onlyRoom';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { groupNameList: string[]; mode?: 'default' | 'onlyRoom' },
    private dialogRef: MatDialogRef<AddRoomDialogComponent, any>,
  ) {}

  nameControl = new UntypedFormControl();

  ngOnInit() {
    this.init();
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  init() {
    if (this.onlyRoom) {
      this.title = `「${this.data.groupNameList.first()}」に部屋を追加`;
      this.subtitle = '部屋名を入力してください';
    } else {
      this.title = undefined;
      this.subtitle = undefined;
      this.nameControl.setValue(this.inputData.floorName);
      this.filteredGroup$ = this.nameControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          return this.data.groupNameList.filter(
            (name) =>
              name.toLowerCase().includes(value.toLowerCase()) &&
              name !== value,
          );
        }),
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  onSave() {
    if (this.onlyRoom) {
      this.inputData.floorName = this.data.groupNameList.first()!;
    } else {
      this.inputData.floorName = this.nameControl.value;
    }
    if (
      this.typedValue?.length > 0 &&
      this.interiorSelectNewValue.nameControl.value
    ) {
      const roomName = this.interiorSelectNewValue.nameControl.value;
      this.roomNames.push({ name: roomName, id: 0 });
    }
    this.roomNames.forEach((room: InputValue) => {
      this.inputData.roomGroups.push(
        Object.assign(this.initRoom(), { name: room.name, id: room.id }),
      );
    });
    this.dialogRef.close(this.inputData);
  }

  initRoom() {
    return {
      id: 0,
      project_id: 0,
      name: '',
      finish_schedule_order: 1,
      finish_schedule_remarks: '',
      remarks: '',
      floor_group_id: 0,
      interior_set_id: 0,
      is_approved: false,
      smoke_exhausting_system: null,
      correspondence_grounds: null,
      exemption_grounds: null,
      interior_restriction: null,
      restriction_grounds: null,
      space_type: null,
      falling_ceiling: null,
      sl: null,
      fl: null,
      baseboard_height: null,
      spandrel_wall_height: null,
      peripheral_height: null,
      construction_classifications: (() => {
        const _constructionClassifications: List<string | null> = {};
        Object.values(ConstructionClassification).forEach((key) => {
          _constructionClassifications[key] = null;
        });
        return _constructionClassifications;
      })(),
      ch: null,
      interiorSet: {
        id: 0,
        parts: {},
        created_at: '',
        updated_at: '',
      },
      created_at: '',
      updated_at: '',
      room_blocks: [],
    };
  }
}
