import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Project } from '@shared/models/response/project';
import { FilteredProjectModes } from '@shared/models/response/sub/project-mode';
import { User } from '@shared/models/response/user';
import { UserStore } from 'app/v2/general/core/store/user.store';
import { Observable } from 'rxjs';

export type GetProjectsOptions = {
  page?: number;
  per_page?: number;
  order?: 'asc' | 'desc';
  status?: FilteredProjectModes[];
};

export type GetProjectsResponse = {
  projects: Project[];
  templates: Project[];
  samples: Project[];
  standard_sets: Project[];
};

@Injectable({
  providedIn: 'root',
})
export class UserApi {
  private http = inject(HttpClient);
  private userStore = inject(UserStore);

  baseUrl = '/api/users';

  me(): Observable<User> {
    this.userStore.patchState({ isLoading: true });
    return this.http.get<User>(`${this.baseUrl}/me`);
  }

  getEnableProjectUser(userId: number): Observable<User> {
    return this.http.get<User>(
      `${this.baseUrl}/${userId}/get-enable-project-user`,
    );
  }

  getProjects(
    userId: number,
    options?: GetProjectsOptions,
  ): Observable<GetProjectsResponse> {
    const params = new HttpParams({
      fromObject: {
        ...options,
        status: options?.status ? options.status.join(',') : '',
      },
    });

    return this.http.get<GetProjectsResponse>(
      `${this.baseUrl}/${userId}/get-projects`,
      {
        params,
      },
    );
  }
}
