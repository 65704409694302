import { ServerType } from './environment.model';

export const environment = {
  production: false,
  storagePath: 'https://static.dev.truss.me/',
  //storagePath: '/storage/',
  gaTrackingId: 'UA-58775646-9',
  serverType: ServerType.Dev,
  clarity: "9uqag6gdwb",
  gtmId: 'GTM-NWP44XDR',
  launchDarklyKey: "665ca7738a40331043c3a425",
};
