import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminAuthGuard } from './shared/guard/admin-auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./general/general.module').then((m) => m.GeneralModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canLoad: [AdminAuthGuard],
  },
  {
    path: 'maker-check',
    loadChildren: () =>
      import('app/maker-check/maker-check.module').then(
        (m) => m.MakerCheckModule,
      ),
  },
  {
    path: 'xr',
    loadChildren: () => import('app/xr/xr.module').then((m) => m.XrModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
