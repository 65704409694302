import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
  inject,
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ResolveEnd, Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  ProjectConditions,
  SearchPicklistCategories,
  SearchPicklistCategory,
  SearchPicklistCategoryType,
} from '@shared/models/response/search-picklist-categories';
import { PicklistType } from '@shared/models/response/sub/picklist-type';
import { ProjectService } from '@shared/service/project.service';
import { SearchService } from '@shared/service/search.service';
import { UserService } from '@shared/service/user.service';
import {
  ProjectPageStore,
  ProjectPageType,
} from 'app/v2/general/application/store/project-page.store';
import { PicklistViewModel } from 'app/v2/general/application/view-model/picklist-view-model';
import { UserStore } from 'app/v2/general/core/store/user.store';
import Mikan from 'mikanjs';
import { filter, take } from 'rxjs';

import { SpinnerComponent } from '../../loader/spinner/spinner.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    SpinnerComponent,
    FontAwesomeModule,
    MatDialogModule,
    RouterModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
  ],
  selector: 'gn-search-picklist-category-dialog',
  templateUrl: './search-picklist-category-dialog.component.html',
  styleUrls: ['./search-picklist-category-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class SearchPicklistCategoryDialogComponent implements OnInit {
  private changeDetector = inject(ChangeDetectorRef);
  private router = inject(Router);
  private pageStore = inject(ProjectPageStore);
  private userStore = inject(UserStore);

  readonly user = inject(UserService);
  readonly project = inject(ProjectService);
  readonly search = inject(SearchService);

  searchKeyword: string;
  needProjectCondition = false;
  projectId = this.project.finishSchedule?.id || undefined;
  selectedCategory = this.categories?.first()?.id;
  picklist?: PicklistViewModel;
  user$ = this.userStore.user$;

  get projectConditions(): ProjectConditions {
    return this.project.projectConditions;
  }

  get type() {
    return this.project.picklistType;
  }

  get categories() {
    if (this.type === PicklistType.MiscellaneousWork) {
      let allCategories: SearchPicklistCategories | undefined =
        this.project.searchCategories;
      if (!allCategories) return [];

      // 雑工事が製品存在しないため、ひとまず全タイプをダイアログに表示
      // Object.values()がなぜか使えずスプレッド演算子使用
      return [
        ...allCategories[PicklistType.Interior],
        ...allCategories[PicklistType.Exterior],
        ...allCategories[PicklistType.Outdoor],
        ...allCategories[PicklistType.Joinery],
        ...allCategories[PicklistType.Facility],
        ...allCategories[PicklistType.GeneralWork],
      ];
    } else
      return this.isDisplayGeneralWork
        ? this.project.currentCategories.concat(
            ...this.project.searchCategories![PicklistType.GeneralWork],
          )
        : this.project.currentCategories;
  }

  get isLoading() {
    return !this.categories || this.categories.length == 0;
  }

  get isLoadingProjectId() {
    return !this.projectId;
  }

  get isDisplayGeneralWork() {
    return (
      this.project.finishSchedule &&
      this.user.useFixtures.includes(this.project.finishSchedule!.group!) &&
      this.project.searchCategories &&
      this.project.picklistType === PicklistType.Interior
    );
  }

  constructor(
    private dialogRef: MatDialogRef<
      SearchPicklistCategoryDialogComponent,
      boolean
    >,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      picklist: PicklistViewModel;
    },
  ) {}

  ngOnInit() {
    this.picklist = this.data.picklist;
    this.project.currentPicklist = this.picklist;

    [
      this.search.onLoadSeachCategories$,
      this.project.onLoadedProjectCondtion$,
    ].forEach((s) => {
      s.subscribe(() => {
        this.projectId = this.project.finishSchedule
          ? this.project.finishSchedule.id
          : undefined;
        this.selectedCategory =
          this.project.currentCategories?.first()?.id || '';
        this.changeDetector.markForCheck();
      });
    });
  }

  transitionPage() {
    this.dialogRef.close(true);
    this.router.events
      .pipe(
        filter((event) => event instanceof ResolveEnd),
        take(1),
      )
      .subscribe(() =>
        this.pageStore.setState({
          activePage: ProjectPageType.SearchFromProject,
        }),
      );
  }

  getQueryParams(
    categoryId: string,
    type?: SearchPicklistCategoryType,
    value?: string,
  ): { [key: string]: string } {
    let params = {};

    if (this.projectId && this.picklist) {
      params = Object.assign(params, {
        project: this.projectId,
        picklist: this.picklist.id,
      });
    }

    if (type) {
      params = Object.assign(params, {
        [type.id]: value,
        project: this.projectId,
      });
    }

    if (type && this.picklist) {
      params = Object.assign(params, {
        [type.id]: value,
        project: this.projectId,
        picklist: this.picklist.id,
      });
    }

    if (this.needProjectCondition && !!this.projectConditions?.[categoryId]) {
      const columns = this.projectConditions[categoryId];
      for (const columnId in columns) {
        params = Object.assign(params, { [columnId]: columns[columnId] });
      }
      return params;
    }

    return params;
  }

  insertBreak(name: string) {
    if (Mikan.split(name).includes(' ［')) {
      return Mikan.split(name.replace(' ［', '['));
    }
    return Mikan.split(name);
  }

  insertBreakForParents(name: string) {
    return name.replace('(', '<br/>(');
  }

  onKeywordSearch(form: NgForm) {
    if (this.picklist) {
      this.project.initPicklistType(this.picklist.type);
    }

    const params = {
      ...form.value,
      project: this.projectId,
      picklist: this.picklist?.id,
    };

    this.router.navigate(['/search/keyword'], {
      queryParams: params,
    });

    this.transitionPage();
  }

  scroll(element: string) {
    this.selectedCategory = element.replace('#id-', '');
    document
      .querySelector(element)!
      .scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  getRouterLink(
    category: SearchPicklistCategory,
    subCategoryId?: string,
  ): string {
    if (subCategoryId) {
      const path = `/category/${subCategoryId}/product/${category.default_search_type}`;
      return path;
    } else {
      const path = `/category/${category.id}/product/${category.default_search_type}`;
      return path;
    }
  }
}
