import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import { Project, ProjectState } from '../../domain/types/states/project.state';

@Injectable({
  providedIn: 'root',
})
export class ProjectStore extends ComponentStore<ProjectState> {
  constructor() {
    super({
      projects: [],
      currentProject: undefined,
      activeId: undefined,
      isLoading: false,
      isChanging: false,
      projectsCount: 0,
    });
  }

  projects$: Observable<Project[]> = this.select((state) => state.projects);

  activeId$: Observable<number | undefined> = this.select(
    (state) => state.activeId,
  );

  currentProject$: Observable<Project | undefined> = this.select(
    (state) => state.currentProject,
  );

  isLoading$: Observable<boolean> = this.select((state) => state.isLoading);

  isChanging$: Observable<boolean> = this.select((state) => state.isChanging);

  projectsCount$: Observable<number> = this.select(
    (state) => state.projectsCount,
  );

  readonly updateProjects = this.updater(
    (state: ProjectState, project: Project) => {
      const projects = state.projects;
      const projectIndex = projects.findIndex(
        (prevProject) => prevProject.id === project.id,
      );

      if (projectIndex === -1) {
        projects.unshift(project);
      } else {
        projects[projectIndex] = project;
      }

      return { ...state, projects: projects };
    },
  );

  readonly deleteProject = this.updater(
    (state: ProjectState, projectId: number) => {
      const projects = state.projects.filter(
        (project) => project.id !== projectId,
      );
      return { ...state, projects: projects };
    },
  );

  readonly updateManyProjects = this.updater(
    (state: ProjectState, projects: Project[]) => {
      return { ...state, projects: projects };
    },
  );

  readonly updateCurrentProject = this.updater(
    (state: ProjectState, currentProject: Project) => {
      const activeId = currentProject.id;
      return { ...state, currentProject: currentProject, activeId: activeId };
    },
  );

  readonly addProject = this.updater((state: ProjectState, value: Project) => {
    return { ...state, projects: [...state.projects, value] };
  });

  readonly updateOne = this.updater((state, newItem: Project) => {
    const updatedItems = state.projects.map((item) => {
      if (item.id === newItem.id) {
        return newItem;
      }
      return item;
    });

    return { ...state, projects: updatedItems };
  });

  readonly updateProjectsCount = this.updater(
    (state: ProjectState, excludedStatus: string[]) => {
      if (excludedStatus.length === 0) {
        return { ...state, projectsCount: state.projects.length };
      }

      const currentProjects = state.projects;
      const newCount = currentProjects.filter((project) =>
        excludedStatus.includes(project.info_project_status),
      ).length;

      return { ...state, projectsCount: newCount };
    },
  );
}
