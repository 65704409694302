<mat-dialog-content>
  <table>
    <tr>
      <td colspan="2">{{ data.caption }}</td>
    </tr>
    <tr>
      <th>材料名<span>*</span></th>
      <td>
        <input
          autocomplete="off"
          name="name"
          type="text"
          [(ngModel)]="inputData.picklist.name"
          placeholder="「タイル(A)」のように一般名を入力"
        />
        <span class="error" *ngIf="errorOfName">{{ errorOfName }}</span>
      </td>
    </tr>
    <tr>
      <th>仕様</th>
      <td>
        <input
          name="specification"
          type="text"
          [(ngModel)]="inputData.picklist.specification"
          placeholder="形状や機能など「公共工事標準仕様書」の仕様を入力"
        />
      </td>
    </tr>
    <tr *lsAllowedGroups="['daiwa']">
      <th>厚み</th>
      <td>
        <input
          class="thickness"
          autocomplete="off"
          name="product_thickness"
          type="number"
          [(ngModel)]="inputData.picklist.thickness"
        />mm
      </td>
    </tr>
    <tr>
      <th>材料表備考</th>
      <td>
        <input
          name="remarks"
          type="text"
          [(ngModel)]="inputData.picklist.remarks"
          placeholder="材料表の備考欄に記載する内容があれば入力"
        />
      </td>
    </tr>
    <tr>
      <th>材料メモ</th>
      <td>
        <textarea
          name="memo"
          type="text"
          [(ngModel)]="inputData.picklist.memo"
          placeholder="詳細な材料の選定基準や同等品の範囲、積算時のグレード感などの共有事項があれば入力"
        ></textarea>
      </td>
    </tr>
    <tr *ngIf="(user.useBootone$ | async) === false">
      <th>2枚貼り可能</th>
      <td>
        <mat-slide-toggle
          color="primary"
          [(ngModel)]="inputData.picklist.enable_multiple_paste"
        >
        </mat-slide-toggle>
      </td>
    </tr>
    <!-- <tr *ngIf="copyFrom">
      <th>複製方法</th>
      <td>
        <mat-checkbox
          class="copy-mode"
          name="copy-mode-with-product"
          [(ngModel)]="inputData.copyInfo!.needProduct"
        >
          採用・候補製品も複製する</mat-checkbox
        >
        <mat-checkbox
          *ngIf="copyTo"
          class="copy-mode"
          name="copy-mode-with-paste"
          [(ngModel)]="inputData.copyInfo!.needPaste"
          >{{ copyTo }}に貼り付け</mat-checkbox
        >
      </td>
    </tr> -->
  </table>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="left">
    <ls-button (click)="cancel()" [border]="true">キャンセル</ls-button>
  </div>
  <div class="right">
    <ls-button
      (click)="onSave()"
      theme="primary"
      [border]="true"
      [class.disabled]="!canSubmit"
      >作成</ls-button
    >
  </div>
</mat-dialog-actions>
