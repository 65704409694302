import { MatPaginatorIntlJaModule } from '@admin/shared/ui/mat-paginator-intl/mat-paginator-intl-ja.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import {
  MatLegacySnackBarModule as MatSnackBarModule,
  MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/legacy-snack-bar';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ActivatedRoute,
  Router,
  RouterModule,
  provideRouter,
} from '@angular/router';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { ProjectPageService } from '@general/project/project-page.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomErrorHandler } from './error/custom-error-handler';
import { httpInterceptorProviders } from './http-interceptors';
import { registerLocale } from './initial-set-up/register-locale';
import { setUpGoogleAnalytics } from './initial-set-up/set-up-google-analytics';
import { setUpIcons } from './initial-set-up/set-up-icons';
import { setUpTitle } from './initial-set-up/set-up-title';
import { AppStoreModule } from './store/app-store.module';
import { FinishScheduleStore } from './v2/general/application/store/finish-schedule.store';
import { LogoComponent } from './v2/general/shared/views/logo/logo.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    OverlayModule,
    FontAwesomeModule,
    AppStoreModule,
    MatDialogModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    LogoComponent,
    MatProgressSpinnerModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    httpInterceptorProviders,
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2500,
        verticalPosition: 'bottom',
        horizontalPosition: 'right',
        panelClass: 'custom-snackbar',
      },
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlJaModule,
    },
    {
      provide: FinishScheduleStore,
      useClass: FinishScheduleStore,
    },
    ProjectPageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    inconLibrary: FaIconLibrary,
  ) {
    setUpGoogleAnalytics();
    registerLocale();
    setUpIcons(inconLibrary);
    setUpTitle(router, route, title);
  }
}
