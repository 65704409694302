import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListProduct } from '@shared/models/response/list-product';
import { Picklist } from '@shared/models/response/picklist';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ProductChartItemsParams } from '../models/request/product-chart-items-params';
import { ProductSearchParams } from '../models/request/product-search-params';
import {
  FilteredVariationTotalCount,
  ProductVariationRequest,
} from '../models/request/product-variation-request';
import { BookmarkProduct } from '../models/response/bookmark-product';
import { ChartItem } from '../models/response/chart-item';
import { ChartItemMeta } from '../models/response/chart-item-meta';
import { Count } from '../models/response/count';
import { Feature } from '../models/response/feature';
import { Maker } from '../models/response/maker';
import { Pagination } from '../models/response/pagination';
import { Product } from '../models/response/product';
import { ProductCache } from '../models/response/product-cache';
import { ProductFavoriteCount } from '../models/response/product-favorite-count';
import { Series } from '../models/response/series';
import { PicklistProductStatus } from '../models/response/sub/picklist-product-status';
import { UseCase } from '../models/response/use-case';
import { VariationSelection } from '../models/response/variation-selection';
import { setFavoriteState } from '../service/favorite-state.service';

import { RequestParamsConverterService } from './request-params-converter.service';

export interface FavoriteParam {
  product_id: string;
  unique_key: string;
  is_fav: boolean;
  list_status?: PicklistProductStatus;
  product?: Product;
}

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  onBeginFavChange$ = new Subject<void>();

  constructor(
    protected http: HttpClient,
    protected converter: RequestParamsConverterService,
  ) {}

  index(): Observable<Pagination<Product>> {
    return this.http
      .get<Pagination<Product>>('/api/products')
      .pipe(tap((pagination) => setFavoriteState(...pagination.data)));
  }

  show(id: string, currentPicklistId?: number): Observable<Product> {
    const params = new HttpParams().set(
      'to_fav_list_id',
      currentPicklistId ? currentPicklistId.toString() : '',
    );
    return this.http
      .get<Product>(`/api/products/${id}`, { params: params })
      .pipe(tap(setFavoriteState));
  }

  getProductByUniquekey(uniqueKey: string): Observable<Product> {
    const params = { unique_keys: [uniqueKey] };
    return this.http
      .post<Product[]>(`/api/products/search/by-unique-keys`, params)
      .pipe(map((products) => products.first()!));
  }

  getProductsByUniquekeys(uniqueKeys: string[]): Observable<Product[]> {
    const params = { unique_keys: uniqueKeys };
    return this.http.post<Product[]>(
      `/api/products/search/by-unique-keys`,
      params,
    );
  }

  getProductById(id: string): Observable<ProductCache> {
    return this.http.get<ProductCache>(`/api/products/${id}/find`);
  }

  search(params: ProductSearchParams = {}): Observable<Pagination<Product>> {
    return this.http
      .get<Pagination<Product>>(`/api/products/search`, {
        params: this.converter.convert(params),
      })
      .pipe(tap((pagination) => setFavoriteState(...pagination.data)));
  }

  searchWithoutPaginate(
    params: ProductSearchParams = {},
  ): Observable<Product[]> {
    const _params = Object.assign(params, { skip_paginate: true });
    return this.http.get<Product[]>(`/api/products/search`, {
      params: this.converter.convert(_params),
    });
  }

  searchAll(params: ProductSearchParams = {}): Observable<Pagination<Product>> {
    return this.http
      .get<Pagination<Product>>(`/api/products/search/all`, {
        params: this.converter.convert(params),
      })
      .pipe(tap((pagination) => setFavoriteState(...pagination.data)));
  }

  searchTotal(params: ProductSearchParams = {}): Observable<Count> {
    return this.http.get<Count>(`/api/products/search/total`, {
      params: this.converter.convert(params),
    });
  }

  groupedSearch(
    params: ProductSearchParams = {},
  ): Observable<Pagination<Product>> {
    return this.http
      .get<Pagination<Product>>(`/api/products/grouped/search`, {
        params: this.converter.convert(params),
      })
      .pipe(tap((pagination) => setFavoriteState(...pagination.data)));
  }

  groupedSearchWithoutPaginate(
    params: ProductSearchParams = {},
  ): Observable<Product[]> {
    const _params = Object.assign(params, { skip_paginate: true });
    return this.http.get<Product[]>(`/api/products/grouped/search`, {
      params: this.converter.convert(_params),
    });
  }

  groupedSearchTotal(params: ProductSearchParams = {}): Observable<Count> {
    return this.http.get<Count>(`/api/products/grouped/search/total`, {
      params: this.converter.convert(params),
    });
  }

  groupedSearchTotalEachCategory(
    params: ProductSearchParams = {},
    categoryIds: string[],
  ): Observable<{ [key: string]: number }> {
    const _params: ProductSearchParams = Object.assign(params, {
      categories: categoryIds,
    });
    return this.http.get<{ [key: string]: number }>(
      `/api/products/grouped/search/total-each-category`,
      {
        params: this.converter.convert(params),
      },
    );
  }

  summary(params: ProductSearchParams = {}): Observable<{}> {
    return this.http.get<{}>(`/api/products/summary`, {
      params: this.converter.convert(params),
    });
  }

  chartItems(params: ProductChartItemsParams): Observable<ChartItem[]> {
    return this.http.get<ChartItem[]>(`/api/products/chart-items`, {
      params: this.converter.convert(params),
    });
  }

  chartItemsTotal(params: ProductChartItemsParams): Observable<ChartItemMeta> {
    return this.http.get<ChartItemMeta>(`/api/products/chart-items/total`, {
      params: this.converter.convert(params),
    });
  }

  series(id: string): Observable<Series> {
    return this.http.get<Series>(`/api/products/${id}/series`);
  }

  features(id: string): Observable<Feature[]> {
    return this.http.get<Feature[]>(`/api/products/${id}/features`);
  }

  useCases(id: string): Observable<UseCase[]> {
    return this.http.get<UseCase[]>(`/api/products/${id}/useCases`);
  }

  maker(id: string): Observable<Maker> {
    return this.http.get<Maker>(`/api/products/${id}/maker`);
  }

  setFavorite(id: string, isFav: boolean): Observable<Product> {
    this.onBeginFavChange$.next();
    return isFav
      ? this.http
          .post<Product>(`/api/products/${id}/favorite`, null)
          .pipe(tap(setFavoriteState))
      : this.http
          .delete<Product>(`/api/products/${id}/favorite`)
          .pipe(tap(setFavoriteState));
  }

  setFavoriteToGroup(
    favoriteParams: FavoriteParam[],
    favListId?: number,
  ): Observable<Product[]> {
    const params = { params: favoriteParams, fav_list_id: favListId };
    this.onBeginFavChange$.next();
    return this.http
      .post<Product[]>(`/api/products/group/favorite`, params)
      .pipe(tap((p) => setFavoriteState(...p)));
  }

  favoriteCount(
    categories: string[] = [],
    favListId?: string,
  ): Observable<ProductFavoriteCount> {
    const params = this.converter.convert({
      categories,
      fav_list_id: favListId,
    });
    return this.http.get<ProductFavoriteCount>(`/api/products/favorite/count`, {
      params,
    });
  }

  variations(params: ProductVariationRequest): Observable<Pagination<Product>> {
    return this.http.get<Pagination<Product>>(`/api/products/variations`, {
      params: this.converter.convert(params),
    });
  }

  firstVariation(params: ProductVariationRequest): Observable<Product> {
    return this.http.post<Product>(`/api/products/variations/first`, params);
  }

  variationSelections(id: string): Observable<VariationSelection[]> {
    return this.http.get<VariationSelection[]>(
      `/api/products/${id}/variation-selections`,
    );
  }

  filteredVariation(
    params: ProductVariationRequest,
  ): Observable<Pagination<Product>> {
    return this.http.post<Pagination<Product>>(
      `/api/products/variations/filtered`,
      params,
    );
  }

  filteredVariationTotal(
    params: ProductVariationRequest[],
  ): Observable<FilteredVariationTotalCount[]> {
    return this.http.post<FilteredVariationTotalCount[]>(
      `/api/products/variations/filtered/filtered-total`,
      params,
    );
  }

  variationTotal(params: ProductSearchParams = {}): Observable<Count> {
    return this.http.get<Count>(`/api/products/variations/variation-total`, {
      params: this.converter.convert(params),
    });
  }

  getDecidedPerformance(
    params: ProductSearchParams = {},
  ): Observable<Pagination<Product>> {
    return this.http
      .get<Pagination<Product>>(`/api/products/decided-performance/list`, {
        params: this.converter.convert(params),
      })
      .pipe(tap((pagination) => setFavoriteState(...pagination.data)));
  }

  postBookmark(
    bookmarkListId: number,
    productUniqueKey: string,
  ): Observable<BookmarkProduct> {
    const params = this.converter.convert({
      product_unique_key: productUniqueKey,
    });
    return this.http.post<BookmarkProduct>(
      `/api/products/bookmark/${bookmarkListId}`,
      {
        params,
      },
    );
  }

  deleteBookmark(
    bookmarkListId: number,
    productUniqueKey: string,
  ): Observable<boolean> {
    return this.http
      .delete(`/api/products/bookmark/${bookmarkListId}/${productUniqueKey}`)
      .pipe(map((value) => !!value));
  }

  addListProduct(
    listProduct: ListProduct,
    picklist: Picklist,
  ): Observable<ListProduct[]> {
    return this.http.post<ListProduct[]>(`/api/products/add_list_product`, {
      listProduct,
      picklist,
    });
  }

  updateListProduct(
    listProduct: ListProduct,
    decidedProduct?: Product,
  ): Observable<ListProduct> {
    return this.http.post<ListProduct>(`/api/products/update_list_product`, {
      listProduct,
      decidedProduct,
    });
  }

  deleteListProduct(listProductId: number): Observable<number> {
    return this.http
      .delete(`/api/products/list_product/${listProductId}`)
      .pipe(map((value) => listProductId));
  }

  getCategoriesByListProduct(
    listProducts: ListProduct[],
    picklist: Picklist,
  ): Observable<
    {
      isManualInput: boolean;
      categoryName: string;
      categoryId: string;
      categoryOrder: number;
      productInfo: {
        listProductId: number;
        productId: string;
        groupKey: string;
      }[];
    }[]
  > {
    const listProductIds = listProducts
      .map((listProduct) => {
        return listProduct.id.toString();
      })
      .join(',');

    const params = new HttpParams()
      .set('list_product_ids', listProductIds)
      .set('picklist_type', picklist.type);

    return this.http.get<
      {
        isManualInput: boolean;
        categoryName: string;
        categoryId: string;
        categoryOrder: number;
        productInfo: {
          listProductId: number;
          productId: string;
          groupKey: string;
        }[];
      }[]
    >(`/api/products/list_product/get_categories_by_listProduct`, { params });
  }
}
