import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Joinery } from '@interfaces/joinery';
import { JoineryComponent } from '@interfaces/joinery-component';
import { SymbolSpecification } from '@interfaces/symbol-specification';
import { SymbolWithNumber } from '@interfaces/symbol-with-number';
import { Picklist } from '@shared/models/response/picklist';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JoineryApiService {
  constructor(private http: HttpClient) {}

  getJoinery(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joineryId: number,
  ): Observable<{ joinery: Joinery; components: JoineryComponent[] }> {
    return this.http.get<{ joinery: Joinery; components: JoineryComponent[] }>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/${joineryId}`,
      {
        params: new HttpParams().set(
          'embeds',
          'components,symbol_specifications',
        ),
      },
    );
  }

  // 標準建具の適用
  // 標準セットを見つけられなかったら、catchErrorでstatuscode700が返って来る
  applyStandardJoinery(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joinery: Joinery,
  ): Observable<{
    joinery: Joinery;
    components: JoineryComponent[];
    picklists: Picklist[];
  }> {
    return this.http.post<{
      joinery: Joinery;
      components: JoineryComponent[];
      picklists: Picklist[];
    }>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/${joinery.id}/from/standards/joineries`,
      { joinery, components: [] },
    );
  }
  // 標準建具の一括適用
  applyStandardJoineries(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
  ): Observable<SymbolWithNumber[]> {
    return this.http.post<SymbolWithNumber[]>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/from/standards/joineries`,
      {},
    );
  }
  // 建具の新規作成、複製も兼ねる
  addJoinery(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joinery: Joinery,
    duplicate = false,
  ): Observable<{ joinery: Joinery; components: JoineryComponent[] }> {
    return this.http.post<{ joinery: Joinery; components: JoineryComponent[] }>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries`,
      {
        joinery: { ...joinery, id: duplicate ? joinery.id : null },
      },
    );
  }
  // 建具の新規作成（連窓・段窓あり）、複製も兼ねる
  addMultiWindowJoinery(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joineries: Joinery[],
    components: JoineryComponent[] = [],
  ): Observable<{ joineries: Joinery[]; components: JoineryComponent[] }> {
    const joineryIdsString = joineries.map((joinery) => joinery.id).toString();
    return this.http.post<{
      joineries: Joinery[];
      components: JoineryComponent[];
    }>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/${joineryIdsString}/bulk`,
      { joineries, components },
    );
  }

  // 建具編集（順序入れ替え）
  updateJoinery(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joinery: Joinery,
  ): Observable<void> {
    return this.http.put<void>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/${joinery.id}`,
      { ...joinery },
    );
  }

  updateSymbolSpecification(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    symbolSpecification: SymbolSpecification,
  ): Observable<void> {
    return this.http.put<void>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/${symbolSpecification.joinery_id}/symbol-specifications/${symbolSpecification.id}`,
      { ...symbolSpecification },
    );
  }

  updateSymbolSpecifications(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    symbolSpecifications: SymbolSpecification[],
  ): Observable<void> {
    const ids = symbolSpecifications.map((s) => s.id).toString();
    return this.http.put<void>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/symbol-specifications/${ids}/bulk`,
      { symbol_specifications: symbolSpecifications },
    );
  }

  remove(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joineryId: number,
  ): Observable<void> {
    return this.http.delete<void>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/${joineryId}`,
    );
  }

  // 建具の複数新規作成
  addManyJoinery(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joineries: Omit<Joinery, 'id'>[],
  ): Observable<{ joineries: Joinery[]; components: JoineryComponent[] }> {
    return this.http.post<{
      joineries: Joinery[];
      components: JoineryComponent[];
    }>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/bulk`,
      {
        joineries: joineries,
      },
    );
  }
}
