export enum UserGroupType {
  Employee = 'employee',
  Partner = 'partner',
  Admin = 'admin',
}

export const getUserGroupTypeLabel = (type: UserGroupType | string) => {
  switch (type) {
    case UserGroupType.Employee:
      return '社員';
    case UserGroupType.Partner:
      return 'パートナー';
    case UserGroupType.Admin:
      return '管理権限';
    default:
      return '社員';
  }
};
